<template>
  <div class="relative w-full m-8">
    <div class="border-r-2 border-red-400 absolute h-full top-0" style="left: 15px"></div>
    <ul class="list-none m-0 p-0">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Timeline"
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="flex flex-row items-center">
    <div class="rounded-full flex p-4" :class="[background]">
      <img :alt="icon" class="m-auto w-8 h-8" :src="imageUrl">
    </div>
    <div class="flex ml-4 flex-col">
      <span class="font-bold text-xl">{{ title }}</span>
      <small>{{ subtitle }}</small>
    </div>
  </div>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
  name: "AboutServiceCard",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    background: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'apple'
    },
  },
  setup(props) {

    const {icon} = toRefs(props)

    const imageUrl = computed(() => {
      return require('@/assets/icons/' + icon.value + '.svg')
    });

    return {
      imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.color-1 {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.color-2 {
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}

.color-3 {
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.color-4 {
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.color-5 {
  background-image: linear-gradient(to bottom, #37ecba 0%, #72afd3 100%);
}

.color-6 {
  background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
}
</style>
export default [
    {
        key: 'education',
        lang: 'en',
        data: [
            {
                title: '2010 / 2015 - <strong>I.T.T.S Fedi-Fermi</strong>',
                subtitle: 'Technical and professional high school diploma in computer science. Learned to use C, Java and PHP'
            }
        ]
    },
    {
        key: 'experience',
        lang: 'en',
        data: [
            {
                title: 'Dec 2020 / Current - <strong>Freelance web and iOS Developer</strong>',
                subtitle: 'Frontend development with <strong>Vue.js 2 and 3</strong> and <strong>NuxtJS</strong><br>' +
                    'Backend development with <strong>Laravel 5^ - 8^</strong> for creating API Rest using <strong>Docker</strong> and <strong>MySQL</strong> as DB\n' +
                    '\n' +
                    'Creating pixel perfect Mockup and transform it into a real project Using HTML/CSS/JS\n' +
                    '\n' +
                    'Using <strong>Bitbucket, Github</strong> for code versioning.'
            },
            {
                title: 'Apr 2019 / May 2021 - <strong>Comm.it S.r.l.</strong>',
                subtitle: 'Frontend development with <strong>Vue.js 2 and 3</strong> for building SPA and <strong>NuxtJS</strong> for creating SEO compliant websites; Development of hybrid application using Ionic with Cordova / Capacitor<br>' +
                    'Develop iOS native applications with Swift + UIKIT and SwiftUI using Realm as local DB<br>' +
                    'Backend development with <strong>Laravel 5^ - 8^</strong> for creating API Rest using <strong>Docker</strong> and <strong>MySQL</strong> as DB\n' +
                    '\n' +
                    'Creating pixel perfect Mockup and transform it into a real project Using HTML/CSS/JS\n' +
                    '\n' +
                    'Using <strong>Gitlab</strong> for code versioning.'
            },
            {
                title: 'Aug 2017 / Apr 2019 - <strong>Meritocracy.is</strong>',
                subtitle: 'Maintain and implement new software features including integrations with ATS or services such as Linkedin / Facebook. <br> ' +
                    'Design and implementation of new sections / components suitable to improve the user experience on the application starting from the bases and from pre-existing elements.<br>' +
                    'Frontend development with <strong>Vue.js using Vue-router for building single page applications (SPA)</strong> <br>' +
                    'Backend development in <strong>LEMP environment on Laravel 5 and Nodejs using Adonis Js framework</strong> <br>' +
                    'Frontend development with HTML 5, jQuery and CSS\n' +
                    'Using GitHub for code versioning.\n' +
                    'Use of Asana for the organization of the team\n' +
                    'API integrations of ATS, CRM, Social'
            },
            {
                title: 'Mar 2016 / Aug 2017 - <strong>Sinerbit S.r.l</strong>',
                subtitle: 'Design and implement the functional foundations of a new software starting from the Database up to the selection of the right technologies according to the type of project to be developed.<br>' +
                    '<strong>Design and construct modern and functional UI starting from wireframe</strong>, suitable for a B2B environment but always modern.<br>' +
                    'Management of a small development team in order to progress quickly and methodically in the creation of an easily maintainable and quality software.<br>' +
                    'Backend development in the <strong>LEMP environment on Laravel 5</strong><br>' +
                    'Local deploy on Vagrant machine<br>' +
                    'Frontend development with HTML 5, jQuery and CSS<br>' +
                    'Use of <strong>BitBucket</strong> to check code version.<br>' +
                    'Use of software such as <strong>Jira / Trello / Asana</strong> for the organization of the team'
            },
        ]
    },
];
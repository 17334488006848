<template>
  <div>
    <!-- Hero -->
    <section class="flex w-full bg-white px-4 pb-20 py-20 md:py-48 text-theme-dark">
      <div class="m-auto grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-xl">
        <div class="flex px-2 md:px-20">
          <div class="m-auto text-center md:text-left">
            <small class="text-lg font-medium text-red-700">Hi, i am</small>
            <h2 class="text-5xl md:text-7xl py-2 font-bold">Luca Giardi</h2>
            <h3 class="text-xl md:text-3xl">Freelance full Stack Web and iOS Developer,<br> UI Designer</h3>
          </div>
        </div>
        <div class="flex mt-24 md:mt-0">
          <div class="m-auto rounded-3xl shadow-2xl transition transition-all duration-200 ease-in-out" style="background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);">
            <div class="pl-4 pr-4 flex fadeUp" :class="{'show':showImages}">
              <img alt="me" class="m-auto -mt-20" style="height: 30rem" src="@/assets/about/me-no-bg.webp">
            </div>
            <div class="bg-white grid grid-cols-4 p-4 rounded-b-3xl">
              <SocialLink type="linkedin"/>
              <SocialLink type="twitter"/>
              <SocialLink type="github"/>
              <SocialLink type="telegram"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- WHAT I OFFER -->
    <AboutSection title="Specialize in" subtitle="What i offer">
      <div class="m-auto bg-white w-full rounded-lg py-8 md:py-24 text-theme-dark max-w-screen-xl">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="flex flex-col justify-center items-center">
            <div class="grid grid-cols-1 px-4 gap-4">
              <AboutServiceCard background="color-1" icon="diamond" title="6+ Years of experience"
                                subtitle="As Full stack web developer"/>
              <AboutServiceCard background="color-2" icon="friendly" title="iOS Development"
                                subtitle="Swift with UIKit and SwiftUI"/>
              <AboutServiceCard background="color-3" icon="assets" title="Frontend Development"
                                subtitle="Using VueJS,NuxtJS and Vanilla JS"/>
            </div>
          </div>
          <div class="flex flex-col justify-center items-center">
            <div class="grid grid-cols-1 px-4 gap-4">
              <AboutServiceCard background="color-4" icon="droid" title="Backend Development"
                                subtitle="Using Laravel from 5 to 8^"/>
              <AboutServiceCard background="color-5" icon="adapt" title="UI Design"
                                subtitle="Design mockup for application and websites"/>
              <AboutServiceCard background="color-6" icon="time" title="Precision"
                                subtitle="Providing the best code quality"/>
            </div>
          </div>
        </div>
      </div>
    </AboutSection>

    <!-- TECH STACK -->
    <AboutSection title="My instruments" subtitle="TECH STACK & TOOLS" white>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 m-auto max-w-screen-xl">
        <AboutTechCard title="Vue JS" subtitle="3+ years of experience and 2+ years of experience using NuxtJS"
                       icon="vuejs"/>
        <AboutTechCard title="Swift & SwiftUI" subtitle="2+ years of experience with Swift (UIKit) and SwiftUI"
                       icon="swift"/>
        <AboutTechCard title="Laravel"
                       subtitle="6+ years of experience starting from 5^ to latest version for developing API Rest"
                       icon="laravel"/>
        <AboutTechCard title="CSS 3" subtitle="6+ years of experience in creating pixel perfect products" icon="css"/>
        <AboutTechCard title="MySQL" subtitle="Database design and implementation from scratch" icon="mysql"/>
        <AboutTechCard title="Sketch"
                       subtitle="Wireframe & Mockup design component oriented for a faster and precise development"
                       icon="sketch"/>
        <AboutTechCard title="Git" subtitle="Code versioning using Bitbucket, Github, Gitlab" icon="git"/>
        <AboutTechCard title="Laravel forge" subtitle="Configure and deploy VueJS and Laravel project"
                       icon="laravelforge"/>

      </div>
    </AboutSection>

    <!-- EDUCATION & EXPERIENCE -->
    <AboutSection title="My qualifications" subtitle="education & experience">
      <div class="grid grid-cols-1 gap-24 max-w-screen-xl m-auto">
        <div class="flex flex-col justify-center items-center">
          <small class="text-left text-xl font-medium text-red-400">Education</small>
          <Timeline class="m-auto pt-12">
            <TimelineItem v-for="(item,index) in education" :key="index" :title="item.title" :subtitle="item.subtitle"/>
          </Timeline>
        </div>
        <div class="flex flex-col justify-center items-center">
          <small class="text-left text-xl text-red-400 font-medium">Experience</small>
          <Timeline class="m-auto pt-12">
            <TimelineItem v-for="(item,index) in experience" :key="index" :title="item.title"
                          :subtitle="item.subtitle"/>
          </Timeline>
        </div>
      </div>
    </AboutSection>

    <!-- MY PORTFOLIO -->
    <AboutSection title="My portfolio" subtitle="recent work" white>
      <div class="m-auto flex bg-white w-full py-12 max-w-screen-xl">
        <div class="w-full m-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <AboutPortfolioCard img-url="portfolio/bitelife/bitelife.webp" url="/portfolio/bitelife" title="Bitelife"/>
            <AboutPortfolioCard img-url="portfolio/bitelife-admin/bitelife-admin.webp" url="/portfolio/bitelife-admin"
                                title="Bitelife Admin"/>
            <AboutPortfolioCard img-url="portfolio/meritocracy/meritocracy.webp" url="/portfolio/meritocracy"
                                title="Meritocracy"/>
            <AboutPortfolioCard img-url="portfolio/timerly/timerly.webp" url="/portfolio/timerly" title="Timerly"/>
          </div>
        </div>
      </div>
    </AboutSection>
  </div>
</template>

<script>

import {onMounted, ref, computed} from "vue";
import AboutServiceCard from "@/modules/about/components/AboutServiceCard";
import Timeline from "@/components/timeline/Timeline";
import TimelineItem from "@/components/timeline/TimelineItem";
import AboutPortfolioCard from "@/modules/about/components/AboutPortfolioCard";
import AboutTechCard from "@/modules/about/components/AboutTechCard";
import AboutSection from "@/modules/about/components/AboutSection";
import aboutMeConstants from "@/lib/constants/aboutMeConstants";
import SocialLink from "@/components/SocialLink";

export default {
  name: "home",
  components: {SocialLink, AboutSection, AboutTechCard, AboutPortfolioCard, TimelineItem, Timeline, AboutServiceCard},
  setup() {

    const showImages = ref(false)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showImages.value = true;
      }, 100);
    })

    const education = computed(() => {
      return aboutMeConstants.filter(x => x.key === 'education')[0].data
    });

    const experience = computed(() => {
      return aboutMeConstants.filter(x => x.key === 'experience')[0].data
    });

    return {
      showImages,
      education,
      experience
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
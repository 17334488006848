<template>
  <section class="flex flex-col w-full pt-12 md:pt-20 pb-24 md:pb-36 px-4" :class="{'bg-white text-theme-dark' : white}">
    <div class="text-center py-8">
      <small class="text-base font-medium text-red-700">{{ title }}</small>
      <h3 class="text-4xl font-bold uppercase mt-2">{{ subtitle }}</h3>
    </div>
    <div class="mt-12">
      <slot/>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    white: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>
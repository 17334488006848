<template>
  <a :href="link" target="_blank" class="flex w-full">
    <img alt="linkedin" class="m-auto" :class="iconSizeClasses" :src="require('@/assets/icons/social/' + type + '.svg')">
  </a>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
  name: "SocialLink",
  props: {
    type: {
      type: String,
      default: 'linkedin'
    },
    size: {
      type: Number,
      default: 12
    }
  },
  setup(props) {

    const {size, type} = toRefs(props)

    const link = computed(() => {
      switch (type.value) {
        case 'linkedin':
        case 'linkedin-white':
          return 'https://www.linkedin.com/in/luca-giardi-91a01288/'
        case 'twitter':
        case 'twitter-white':
          return 'https://twitter.com/LucaGiardi'
        case 'github':
        case 'github-white':
          return 'https://github.com/LucaGiardi20'
        case 'telegram':
        case 'telegram-white':
          return 'https://t.me/LucaGiardi'
        default:
          return ''
      }
    });

    const iconSizeClasses = computed(() => {
      return 'w-' + size.value + ' h-' + size.value
    });

    return {
      link,
      iconSizeClasses
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <li class="mb-12">
    <div class="flex items-start">
      <div class="bg-red-400 rounded-full h-8 w-8"></div>
      <div class="flex-1 pl-4 font-medium border-b border-red-400 border-dotted" v-html="title"></div>
    </div>
    <div class="ml-12 mt-4 text-lg leading-8" v-html="subtitle"></div>
  </li>
</template>

<script>
export default {
  name: "TimelineItem",
  props: {
    title: {
      type: String,
      default: 'Oct 2017 - First Release'
    },
    subtitle: {
      type: String,
      default: 'First release of Tailwind CSS'
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <router-link class="portfolio-card" :to="url">
    <div class="portfolio-card__overlay">
      <span>{{ title }}</span>
    </div>
    <div class="portfolio-card__background" :style="{ backgroundImage: 'url(' + require('@/assets/'+imgUrl) + ')' }"/>
  </router-link>
</template>

<script>
export default {
  name: "AboutPortfolioCard",
  props: {
    title: {
      type: String,
      required: true
    },
    url:{
      type:String,
    },
    imgUrl: {
      type: String,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.portfolio-card {
  @apply relative flex w-full h-64;
  -webkit-transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
  transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);

  &__overlay {
    @apply inset-0 absolute z-10 flex opacity-0;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
    transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);

    span {
      @apply m-auto z-30 text-center text-white text-6xl font-bold;
      -webkit-transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
      transition: all 0.75s cubic-bezier(0, 1, 0.5, 1);
    }
  }


  &__background {
    @apply flex bg-white w-full h-full hover:shadow-xl bg-no-repeat bg-cover m-auto;
  }


  &:hover {

    .portfolio-card__overlay {
      opacity: 1;
    }
  }
}
</style>
<template>
  <div class="about-tech-card">
    <div class="w-12 h-12 -mt-5 ml-10 flex rounded-xl" :style="{'background-color':backgroundColor}">
      <img :alt="icon" class="m-auto" :src="imageUrl">
    </div>
    <div class="grid grid-cols-1 gap-1 px-10 py-6">
      <span class="font-bold text-xl">{{ title }}</span>
      <span class="font-medium text-base">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import {toRefs, computed} from 'vue'

export default {
  name: "AboutTechCard",
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    }
  },
  setup(props) {

    const {icon} = toRefs(props)

    const imageUrl = computed(() => {
      return require('@/assets/icons/tools/' + icon.value + '.svg')
    });

    return {
      imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.about-tech-card {
  @apply bg-white rounded-t-3xl border border-gray-200;
  min-height: 12rem;
}
</style>